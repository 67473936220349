<template>
  <div class="page">
    <div class="pageFucView">
      <div class="pageFucView_left">
        <el-button
          icon="el-icon-plus"
          size="small"
          style="margin: 10px 0 10px 10px"
          type="primary"
          @click="handleEdit"
        >
          新增标签
        </el-button>
      </div>
      <div class="pageFucView_right"></div>
    </div>
    <el-table :data="list">
      <el-table-column
        align="center"
        label="ID"
        prop="id"
        show-overflow-tooltip
        width="100"
      />
      <el-table-column
        align="center"
        label="模板名称"
        min-width="120"
        prop="name"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="简介"
        min-width="120"
        prop="name_title"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="计费模式"
        min-width="120"
        prop="billing_pattern"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <span v-if="row.billing_pattern != 1">一口价</span>
          <span v-else>常规</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="是否启用"
        min-width="120"
        prop="start"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <span v-if="row.start != 1">否</span>
          <span v-else>是</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="创建时间"
        min-width="120"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.create_at | formatDate }}
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="120">
        <template #default="{ row }">
          <!--          <el-button type="text" size="small" @click="handleInfo(row)">配置详情</el-button>-->
          <el-button type="text" size="small" @click="handleEdit(row)"
            >编辑</el-button
          >
          <el-button type="text" size="small" @click="handleDelete(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pageView">
      <el-pagination
        :current-page="queryForm.curr_page"
        layout="prev, pager, next"
        :page-size="queryForm.page_size"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { apiGetCourierlList } from "@/request/api";
import { formatDate } from "@/utils/theTimeStamp";

export default {
  name: "courierDemoList",
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      // console.log(new Date(time))
      return formatDate(date, "yyyy-MM-dd hh:mm");
    },
  },
  data() {
    return {
      list: [],
      listLoading: true,
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      selectRows: "",
      queryForm: {
        curr_page: 1,
        page_size: 10,
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val;
    },
    //查看配置
    handleInfo(row) {
      this.$router.push({
        name: "setlogistics",
        query: {
          id: row.id,
        },
      });
    },
    //新增/编辑
    handleEdit(row) {
      console.log(row);
      if (row.id) {
        this.$router.push({
          name: "freightDemo",
          query: {
            id: row.id,
          },
        });
      } else {
        this.$router.push({
          name: "freightDemo",
        });
      }
    },
    //删除
    handleDelete(row) {
      console.log(row);
      /*if (row.id) {
        this.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { code, code_user_msg } = await delMeberList({
            employee_id: sessionStorage.getItem('uid'),
            token_key: sessionStorage.getItem('token'),
            id: row.id,
          })
          if (code == 200) {
            this.$baseMessage(code_user_msg, 'success')
            await this.fetchData()
          } else {
            this.$baseMessage(code_user_msg, 'error')
          }
        })
      } else {
        if (this.selectRows.length > 0) {
          const ids = this.selectRows.map((item) => item.id).join()
          this.$baseConfirm('你确定要删除选中项吗', null, async () => {
            const { code, code_user_msg } = await delBatchMeber({
              ids,
              employee_id: this.queryForm.employee_id,
              token_key: this.queryForm.token_key,
            })
            if (code == 200) {
              this.$baseMessage(code_user_msg, 'success')
              await this.fetchData()
            } else {
              this.$baseMessage(code_user_msg, 'error')
            }
          })
        } else {
          this.$baseMessage('未选中任何行', 'error')
          return false
        }
      }*/
    },
    handleSizeChange(val) {
      this.queryForm.page_size = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.queryForm.curr_page = val;
      this.fetchData();
    },
    queryData() {
      this.queryForm.curr_page = 1;
      this.fetchData();
    },
    async fetchData() {
      this.listLoading = true;
      apiGetCourierlList(this.queryForm).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
        }
      });
      /*const { code, result } = await getMeberList(this.queryForm)
      console.log(result)
      if (code == 200) {
        const { data, total } = result
        const len = data.length
        for (let i = 0; i < len; i++) {
          if (data[i]['entry_date'] == null) {
            data[i]['entry_date'] = parseTime(
                data[i]['create_at'],
                '{yyyy}-{mm}-{dd}'
            )
          }
        }
        this.list = data
        this.total = total
      }*/
      this.listLoading = false;
    },
  },
};
</script>
<style scoped>
.page {
  background-color: #fff;
  padding: 20px;
}
.pageFucView {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.pageView {
  display: flex;
  justify-content: flex-end;
}
</style>
